*[data-v-cbc0ec4c] {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
body[data-v-cbc0ec4c] {
  width: 100vw;
  height: 100vh;
  background-color: #10121f;
  padding: 1vh;
}
body .monitorViode[data-v-cbc0ec4c] {
  width: 100%;
  height: 100%;
  padding-top: 20px;
  position: relative;
}